import React from 'react';

import { useTheme } from '../../../utils/context/theme-context';
import { VARIANTS } from '../../../utils/types';
import { convertContentfulVariantToEnum } from '../utils';
import HeroCallToActionV2 from './hero-call-to-action-v2/HeroCallToActionV2';
import HeroCallToAction from './hero-call-to-action/HeroCallToAction';
import { HeroCallToActionProps } from './utils';

const HeroCallToActionWrapper = (heroCtaProps: HeroCallToActionProps) => {
    const variant = heroCtaProps.variant;
    const enumVariant = convertContentfulVariantToEnum(variant);
    const theme = useTheme();
    if (
        theme.theme == 'Core' &&
        variant &&
        enumVariant != undefined &&
        [
            VARIANTS.PURE_WHITE,
            VARIANTS.GLOW_BRITE,
            VARIANTS.WARM_DARK,
            VARIANTS.WARM_WHITE,
        ].includes(enumVariant)
    )
        return <HeroCallToActionV2 {...heroCtaProps} />;
    else return <HeroCallToAction {...heroCtaProps} />;
};
export default HeroCallToActionWrapper;
